import axiosInstance from './axiosInstance';

// constants
import { API_URL_NEW } from '../lib/endpoints'

// helpers
import { prepareSelectParam } from '../lib/helpers';

// common functions for all entities

const entityApi = {

    getCompanyEmail: (entityId, entityType) => {
        const fields = prepareSelectParam(["email_relations"]);

        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axiosInstance.get(url).then((response) => {
                resolve(response?.data?.data)
            }).catch(err => {
                reject(err)
            })
        });
    },


    getEntityDetails: (entityId, entityType,fieldsArr) => {
        const fields = prepareSelectParam(fieldsArr);
        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axiosInstance.get(url).then((response) => {
                const resp = response.data.data;
                if (response.data.status == 200 && resp.id) {
                    resolve(resp);
                }
            }).catch(error => {
                reject(error);
            })
        });
    }
}

export default entityApi;
