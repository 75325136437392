import React, { useState, useEffect } from 'react';

// helpers
import { getItemWebUrl, removeDuplicateData, prepEntityTypeLower } from '../../../../../../lib/helpers';

// for startup/investor hover component
import { hoverHelpers } from 'shared-helpers';
//api
import startupApi from '../../../../../../apis/startupApi';

//constants
import { ALLOWED_QUERY_OPERATOR } from '../../../../../../lib/constants';


function getInvUrl(inv) {
    let investorUrl = '';
    const url = 'https://magnitt.com/';

    if (inv && (inv.type.toLowerCase() === 'user')) {
        investorUrl = undefined;
    } else {
        investorUrl = getItemWebUrl(prepEntityTypeLower(inv.type), inv.id, inv.code);
    }

    return url + investorUrl;
}

function getInvImg(inv) {
    // handle test data without investor name
    if (inv && inv.name) {
        let invImage = inv && inv.logo;
        if (invImage) {
            invImage = <div className='startupLogo' style={{ backgroundImage: `url('${invImage}')` }}></div>
        } else {
            invImage = <div className='startupLogo'><h2>{inv && inv.name.substr(0, 1).toUpperCase()}</h2></div>
        }
        return invImage;
    } else {
        return <div className='startupLogo'><h2>?</h2></div>
    }
}

export const InvestorDetailRow = ({ investedInName, investorData, setState, investedInId, investedInType }) => {

    const [entityInvestorsData, setEntityInvestorsData] = useState(investorData)
    const [startupInvestorsData, setStartupInvestorsData] = useState({})
    const [isLoading, setIsLoading] = useState(false)


    // update view when investor data changed
    useEffect(() => {
        setEntityInvestorsData(investorData);
    }, [investorData]);


    /*
	* since props.investorData returns max length of 2 for startup directory which is not all the investors
	* for a startup, this function "getStartupInvestorsData" will fetch all investors, store it in startupInvestorsData to prevent refetching of the same data and
	* update entityInvestorsData which is used to display the investors
	*/
    const getStartupInvestorsData = async () => {

        const limit = 20
        let records = []
        setIsLoading(true)

        const tempStartupInvData = startupInvestorsData

        const filter = [{ key: 'verification_status_filter', value: [3], op: ALLOWED_QUERY_OPERATOR.notEq }]
        const page = 1

        const responseData = await startupApi.getInvestors(investedInId, investedInType, limit, page, filter)

        if (responseData.records && responseData.records.length > 0) {

            for (const { entity_data } of responseData.records) {

                if (entity_data && entity_data !== null) {

                    records = removeDuplicateData([...records, entity_data])
                }
            }

            tempStartupInvData[investedInId] = records
            setEntityInvestorsData(records)
            setStartupInvestorsData(tempStartupInvData)
            setState({ selectedInvestorModal: records, investedInName, investedInId }, () => {
                setIsLoading(false)
                showInvestorDetailModal()
            })

        } else {
            // return default data

            tempStartupInvData[investedInId] = entityInvestorsData
            setStartupInvestorsData(tempStartupInvData)
            setState({ selectedInvestorModal: entityInvestorsData, investedInName, investedInId })
            setIsLoading(false)
            showInvestorDetailModal()
        }

    }

    // handles the see all link for startup directory, fetches all investors for a startup if not already fetched
    const showStartupInvestors = () => {

        // should fetch all investors that invested in a startup
        if (!startupInvestorsData[investedInId]) {

            getStartupInvestorsData()
        } else {

            setEntityInvestorsData(startupInvestorsData[investedInId])
            setState({ selectedInvestorModal: startupInvestorsData[investedInId], investedInName, investedInId })
            showInvestorDetailModal()
        }
    }

    const showInvestorDetailModal = () => {
        $("#investors-modal").modal({
            backdrop: 'static', keyboard: true, show: true
        })

        // Remove any hover component
        $(".popover").remove();
    }

    let investors = [];

    entityInvestorsData.map((inv, i) => {

        const invImage = getInvImg(inv, investedInName);
        const investorUrl = getInvUrl(inv);
        inv.type = inv && inv.type && inv.type.toLowerCase();

        // if more than 1 investor
        // display see all link that opens modal w/ full list
        if (i < 1) {

            investors.push(
                <a className="d-flex align-items-center"
                    key={i + "_" + inv.id}
                    rel={investorUrl && "noopener noreferrer"}
                >

                    {invImage}

                    <div style={{ marginLeft: 9 }}>
                        <div className="startup-name"
                            onMouseEnter={(event) => hoverHelpers.handleEntityHover(inv, event, true)}
                            onMouseLeave={() => hoverHelpers.handleMouseOut()}
                        >
                            {(inv && inv.name)}
                        </div>
                    </div>

                </a>
            );

        }
    });

    if (entityInvestorsData.length > 1) {
        investors.push(
            <div style={{ color: '#d31148' }}>

                {investedInType && investedInType == 'startup' ?
                    <>
                        <a onClick={() => showStartupInvestors()} >
							See All
                            <i className="fas fa-chevron-right" style={{ fontSize: 10, marginLeft: 3 }}></i>
                        </a>{" "}{isLoading &&
							<i className="fas fa-circle-notch fa-spin"></i>}    </> :
                    <a onClick={() => setState({ selectedInvestorModal: entityInvestorsData, investedInName })} data-toggle="modal" data-target={"#investors-modal"}>See All</a>
                }
            </div>
        )
    }
    return (investors);
}

export const InvestorDetailModal = ({ investedInName, investorData, investedInId }) => {

    if (!investorData) {
        return null;
    }

    let modalData = [];
    investorData.map((inv, i) => {

        const invImage = getInvImg(inv);
        const investorUrl = getInvUrl(inv);

        modalData.push(
            <a className="d-flex align-items-center"
                key={inv.id + `-${i}-${investedInId ? investedInId : 'uniqueKey'}`}
                href={investorUrl}
                target={investorUrl && "_blank"}
                rel={investorUrl && "noopener noreferrer"}
            >
                {invImage}
                <div style={{ marginLeft: 9 }}>
                    <div className="startup-name"
                        onMouseEnter={(event) => hoverHelpers.handleEntityHover(inv, event, true)}
                        onMouseLeave={() => hoverHelpers.handleMouseOut()}
                    >
                        {(inv && inv.name)}
                    </div>
                </div>
            </a>
        )

    });

    return (
        <div
            className="modal fade"
            id={"investors-modal"}
            role="dialog"
            aria-labelledby=""
            aria-hidden="true"
            data-backdrop="false"
            tabIndex="-1"
        >
            <div className="modelBack-drop" data-dismiss="modal"></div>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title " id="exampleModalLabel">Investors - {investedInName}  </h5>
                        </div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {modalData}
                    </div>
                </div>
            </div>
        </div>
    )

}
