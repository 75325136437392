import React, { Component } from 'react';
import { Helmet } from "react-helmet";
// components
import StartupMarketMapLandscape from './components/StartupMarketMapLandscape/StartupMarketMapLandscape';
import StartupMarketMapCharts from './components/StartupMarketMapCharts/StartupMarketMapCharts';
import StartupMarketMapTable from './components/StartupMarketMapTable/StartupMarketMapTable';
import InvestorMarketMapLandscape from './components/InvestorMarketMapLandscape/InvestorMarketMapLandscape';
import DirectorySlidingContent from './components/DirectorySlidingContent/DirectorySlidingContent';
// constants and helpers
import { WEB_URL } from '../../lib/endpoints'
import MagnittLoader from '../../micro-frontends/MagnittLoader';
import getAccessTokenApi from '../../apis/getTokenApi';
//styles
import './MarketMap.css';


class MarketMap extends Component {
    constructor() {
        super();
        this.state = {
            page: 1,
            isLandscape: true,
            isInvestorLandscape: false,
            isTable: false,
            accessToken: null,

            view: 'finalMap',
            group: [
                { name: 'Primary Industry', id: 1 },
                { name: 'Sub Industry', id: 2 },
                { name: 'Business Type', id: 3 },
                { name: 'Last Stage Raised', id: 4 }
            ],
            investorGroup: [
                { name: 'Investor Type', id: 1 },
                { name: 'Investment Industry', id: 2 },
                { name: 'Investment Stage', id: 3 },
                { name: 'Investment Country', id: 4 },
            ],
            sort: { name: 'Recently Funded', order: 'desc', field: 'last_date_raised', id: 1 },

            directoryCharts: {
                filter: [],
                limit: 9,
                refresh: 0
            },
            directoryNetworkMap: {
                filter: [],
                limit: 9,
                marketMapData: { data: [] },
                refresh: 0
            },
            sliderData: {
                startupsLandScapeTab: [],
                startupsTableTab: [],
                investorsLandScapeTab: []
            }
        };
    }

    async componentDidMount() {
        this.fetchToken()
    }

    fetchToken = async () => {
        const storedToken = localStorage.getItem('accessToken');
        try {
            if (!storedToken) {
                const { newToken } = await getAccessTokenApi.getToken();
                return this.setState({ accessToken: newToken });
            }
            this.setState({ accessToken: storedToken });
        } catch (err) {
            console.error(err);
        }
    };

    changeTab(currentTab) {
        let obj = {};
        obj.isLandscape = false;
        obj.isInvestorLandscape = false;
        obj.isTable = false;
        obj[currentTab] = true;
        this.setState(obj);

        // remove tooltips as it interferes with position of tooltips in new tab
        $(".d3-tooltip").remove();

        //remove landscape tooltips when tab is changed
        $(".popover").remove();
    }

    renderLandscape = () => {
        const { isLandscape, view, group, sort } = this.state;

        return (
            <div className={`${isLandscape ? "" : "d-none"}`}>
                <StartupMarketMapLandscape
                    data={this.state.directoryNetworkMap}
                    view={view}
                    group={group}
                    order={sort}
                    tabIsVisible={isLandscape}
                    updateSliderData={this.updateSliderData}
                />
            </div>
        )
    }

    renderInvestorLandscape = () => {
        const { isInvestorLandscape, view, investorGroup, sort } = this.state;
        return (
            <div className={`${isInvestorLandscape ? "" : "d-none"}`}>
                <InvestorMarketMapLandscape
                    data={this.state.directoryNetworkMap}
                    view={view}
                    group={investorGroup}
                    order={sort}
                    tabIsVisible={isInvestorLandscape}
                    updateSliderData={this.updateSliderData} />
            </div>
        )
    }

    renderTable = () => {
        const { isTable, sort, view } = this.state;
        const { user } = this.props;
        let filter = [];

        // add display-none class to prevent componentDidMount API calls from running multiple times
        return (
            <div className={`${isTable ? "" : "d-none"}`}>
                <StartupMarketMapTable
                    view={view}
                    order={sort}
                    mapFilters={filter}
                    user={user}
                    tabIsVisible={isTable}
                    updateSliderData={this.updateSliderData}
                />
            </div>
        )
    }

    updateSliderData = (data, tabName) => {
        const tempData = this.state.sliderData;
        tempData[tabName] = data;
        this.setState({ sliderData: tempData });
    }

    renderTabs() {
        return (
            <div className="navigation-wrapper">
                <div onClick={() => { this.changeTab('isLandscape') }} className={this.state.isLandscape ? 'activeTab' : 'nonActiveTab'}>
                    <i className="fa fa-th-large"></i>
					Startups Landscape
                </div>
                <div onClick={() => { this.changeTab('isTable') }} className={this.state.isTable ? 'activeTab' : 'nonActiveTab'}>
                    <i className="fa fa-table"></i>
					Startups Table
                </div>
                <div onClick={() => { this.changeTab('isInvestorLandscape') }} className={`hidden-md-down ${this.state.isInvestorLandscape ? 'activeTab' : 'nonActiveTab'}`}>
                    <i className="fa fa-th-large"></i>
					Investors Landscape
                </div>
            </div>
        );
    }

    render() {

        // constants
        const description = 'A collection of venture backed startups categorized based on different factors such as geography, industry etc.'
        const { accessToken, sliderData } = this.state;


        const getFocusedTabName = () => {
            const { isLandscape, isInvestorLandscape, isTable } = this.state;
            if (isLandscape) {
                return 'startupsLandScapeTab';
            }
            if (isTable) {
                return 'startupsTableTab';
            }
            if (isInvestorLandscape) {
                return 'investorsLandScapeTab';
            }

        }

        return (
            <div className="directory content-box footer-margin-offset">

                <Helmet
                    title='Bahrain Startup Ecosystem'
                    link={[
                        {
                            rel: 'canonical',
                            href: `${WEB_URL}/lists/meapt`
                        }
                    ]}
                    meta={[
                        {
                            name: 'description',
                            content: description
                        },
                        {
                            name: 'abstract',
                            content: description
                        },
                        {
                            property: 'og:type',
                            content: 'article'
                        },
                        {
                            property: 'og:url',
                            content: `${WEB_URL}/lists/meapt`
                        },
                        {
                            property: 'og:title',
                            content: 'MEAPT Market Map | MAGNiTT'
                        }
                    ]}
                    script={[
                        {
                            'type': 'application/ld+json',
                            'innerHTML': `{
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "MEAPT Market Map",
                                    "item": "${WEB_URL}/lists/meapt"
                                }]
                            }`
                        }
                    ]}
                />

                <div className="create-edit-form">
                    <div className="mid-width-info-box">
                        <div className="info-box-container">
                            <div className="db-header market-map-header">
                                <div>Bahrain Startup Ecosystem</div>
                                <div className="row map-row">
                                    <div className="col map-description">
                                        <p>
                                            Tamkeen is tasked with developing Bahrain’s private sector and positioning it as the key driver of economic development. Established in August 2006, Tamkeen is one of the cornerstones of Bahrain’s national reform initiatives and Bahrain’s Economic Vision.
                                        </p>
                                        <p>
                                            Tamkeen has two primary objectives: Firstly, fostering the creation and development of enterprises, and secondly, providing support to enhance the productivity and growth of enterprises and individuals.
                                        </p>
                                        <p>
                                            To achieve these objectives, Tamkeen’s strategy focuses on quality enhancement, customer care and having a results-oriented approach. Through this strategy, a number of innovative programmes are provided to Bahrainis and businesses in Bahrain, which include training, consulting, financing, entrepreneurship support and others. These programmes help develop the capabilities of Bahrainis and enterprises and integrate new critical concepts for a vibrant sustainable private sector.
                                        </p>
                                    </div>

                                    <div className="map-logo">
                                        <a className="navbar-brand p-0 m-0" href="https://www.tamkeen.bh" target='_blank'>
                                            <img src="https://magnitt-public-dev.s3.eu-west-2.amazonaws.com/public/images/tamkeen/tamkeen_logo.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="info-box-body preview-box">
                                <div className="form-box-layout">
                                    <section className="content-box network-map-main preview-market-map">
                                        <div>
                                            <div className="network-map-navigation">
                                                {this.renderTabs()}
                                            </div>

                                            {!accessToken ?
                                                <MagnittLoader data={{ height: "300px", background: "transparent" }} /> :
                                                <>
                                                    {this.renderLandscape()}
                                                    {this.renderInvestorLandscape()}
                                                    {this.renderTable()}
                                                </>
                                            }
                                        </div>
                                    </section>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <DirectorySlidingContent
                    // data
                    entityData={sliderData}
                    // functions
                    setState={(s, c) => { this.setState(s, c) }}
                    focusedTabName={getFocusedTabName()}
                />

            </div>
        )
    }
}


export default MarketMap;
