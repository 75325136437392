import axios from 'axios';

// util
import { API_URL_NEW } from '../lib/endpoints';

const getAccessTokenApi = {
    getToken: () => {
        const body = {
            "client_id": process.env.REACT_APP_PAAS_CLIENT_ID
        }

        return new Promise((resolve, reject) => {
            const URL = `${API_URL_NEW}/auth/paas`;

            axios.post(URL, body, {
                headers: { 'Content-Type': 'application/json' }
            }).then(response => {
                if (response?.data?.data?.token) {

                    const newToken = response.data.data.token;
                    const refreshToken = response.data.data.refresh_token;

                    localStorage.setItem('accessToken', newToken);
                    localStorage.setItem('refreshToken', refreshToken);

                    resolve({newToken: response.data.data.token, refreshToken: response.data.data.refresh_token})
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
}

export default getAccessTokenApi;
