import axios from 'axios';
import { API_URL_NEW } from '../lib/endpoints';
import getAccessTokenApi from './getTokenApi';

// Create an instance of axios
const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
    config => {
        // Do something before the request is sent
        // For example, you can add an authorization header
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        // Do something with the error
        return Promise.resolve("");
    }
);

// Add a response interceptor
instance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;
        // check if the error is 403 and the original request has not been retried
        if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            const headers = { 'x-refresh-token': refreshToken };
            const res = await axios.get(`${API_URL_NEW}/auth/refresh`, { headers });
            if (res && res.data.status == 200) {
                const accessToken = res.data.data.access_token;
                localStorage.setItem('accessToken', accessToken);
                instance.defaults.headers.common['Authorization'] = accessToken;
                return instance(originalRequest);
            }
            // if the refresh token is expired, re-login
        } else if (error.response.status === 401) {
            const { newToken } = await getAccessTokenApi.getToken();

            instance.defaults.headers.common['Authorization'] = newToken;
            return instance(originalRequest);
        }
        return Promise.resolve("");
    }
);

export default instance;
