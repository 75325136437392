import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import entityApi from '../../../../../apis/entityApi';

export default class CompanyContactButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entityEmail: "",
            isShowEmail: false,
        };
    }

    componentDidMount = () => {
        const { entityType } = this.props;

        if (entityType !== "user") {
            this.getCompanyEmail();
        }
    };

    // detect if the component should re-render
    // this check is required for startup directory slider, to re-render this component when a new startup is selected
    componentDidUpdate = (prevProps) => {
        const { id } = this.props;

        if (id !== prevProps.id) {
            this.setState({
                entityEmail: "",
                isShowEmail: false
            });

            this.getCompanyEmail();
        }
    }

    getCompanyEmail = () => {
        const { entityType, id } = this.props;
        entityApi.getCompanyEmail(id, entityType).then(
            response => {
                if (!isEmpty(response) && response.email_relations) {
                    this.setState({ entityEmail: response.email_relations });
                }
            }
        );
    };

    handleShowEmail = () => {
        const { isShowEmail } = this.state;

        if (!isShowEmail) {
            $('#companyEmailPopover').popover({
                html: true
            });
            $('#companyEmailPopover').popover('show');
            this.setState({ isShowEmail: !isShowEmail });
        } else {
            $('#companyEmailPopover').popover('hide');
            this.setState({ isShowEmail: !isShowEmail });
        }
    };

    showContactForm = () => {
        const { setState, owner } = this.props

        setState({ owner: owner });

        $('#contactFormModal').modal({
            backdrop: 'static', keyboard: true, show: true
        })
    }

    render() {

        const { entityType } = this.props;
        const {  entityEmail } = this.state;

        if (entityType === "user") {
            return (null);
        } else if (entityEmail.length > 0) {
            return (
                <button
                    id="companyEmailPopover"
                    type="button"
                    class="btn btn-main-outline btn-sm"
                    data-toggle="popover"
                    // title="Company Email"
                    data-content={`<a href='mailto:${entityEmail}' class="team-viewed-email"><i class="fas fa-envelope mr-2 red"></i> ${entityEmail} </a>`}
                    data-placement="bottom"
                    data-offset="-25% 0"
                    onClick={this.handleShowEmail}
                >
                    <i className="fas fa-eye"></i>
                     Company Email
                </button>
            )
        }

        else {
            return (null);
        }

    }
}
