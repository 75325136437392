import { sharedEndpoints } from 'shared-helpers'

// const API_URL_NEW = sharedEndpoints.API_URL_NEW;
const SHARED_WEB_URL = sharedEndpoints.SHARED_WEB_URL;
const WEB_URL = sharedEndpoints.WEB_URL;
// const BIGQUERY_URL = sharedEndpoints.BIGQUERY_URL;

// dev test
// const SHARED_WEB_URL = "http://localhost:4000";
const API_URL_NEW = "https://gateway.magnitt.com/core-api";
const BIGQUERY_URL = "https://gateway.magnitt.com/bigq-api";

// const API_URL_NEW = "https://testgate.magnitt.com/core-api";
// const BIGQUERY_URL = "https://testgate.magnitt.com/bigq-api";

export {
    API_URL_NEW,
    WEB_URL,
    SHARED_WEB_URL,
    BIGQUERY_URL
}
