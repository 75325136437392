import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <main id="MagnittFooter-container">
            <footer id="footer" className="footer">
                <div className="row">
                    <div className="footer-header col-xl-4 col-lg-12">
                        <div className="footer-terms mb-1"><p>Powered by</p></div>
                        <a href="https://magnitt.com/" target="_blank">
                            <img alt="logo" src="https://magnitt-public-dev.s3.eu-west-2.amazonaws.com/public/images/main_logo.png" />
                        </a>
                        <div className="hidden-lg-down">
                            <div className="footer-terms">
                                <p>© 2024 MAGNiTT, Inc. All Rights Reserved &nbsp;</p>
                                <p>
                                    <a href="https://magnitt.com/privacy-policy">Privacy &amp; Cookies </a>
                                    |
                                    <a href="https://magnitt.com/terms-and-conditions">Terms of Service </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </main>

    );
}

export default Footer;
