import React from 'react';
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import MarketMap from './components/MarketMap/MarketMap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const App = () => {
    return (
        <>
            <NavBar />
            <BrowserRouter>
                <div>
                    <Routes>
                        <Route path="/" element={<MarketMap />} />
                    </Routes>
                </div>
            </BrowserRouter>
            <Footer />
        </>
    );
};

export default App;
