import React from 'react';
import './NavBar.css';

export default function NavBar() {
    return (
        <main id="MagnittHeader-container">
            <div id="header-container" className="">
                <nav className="navbar navbar-expand-custom navbar-light bg-light" id="site-header">
                    <a className="navbar-brand pl-0" href="https://magnitt.com" target='_blank'>
                        <img src="https://magnitt-public-dev.s3.eu-west-2.amazonaws.com/public/images/main_logo.png" alt="" />
                    </a>
                </nav>
            </div>
        </main>
    );
}
