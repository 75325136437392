import axiosInstance from './axiosInstance';
import { API_URL_NEW } from '../lib/endpoints';
import { prepareFilterParam } from '../lib/helpers';


const startupApi = {

    getInvestors: (entityId, entityType = "startup", limit = 6, page = 1, getFilters = []) => {

        let filterQuery = '';
        if (getFilters && getFilters.length > 0) {
            filterQuery = `filters=${prepareFilterParam(getFilters)}&`;
        }
        const url = `${API_URL_NEW}/${entityType}/${entityId}/investor?${filterQuery}limit=${limit}&page=${page}`;

        return new Promise((resolve, reject) => {
            axiosInstance.get(url)
                .then((response) => {
                    resolve({
                        records: response.data.data,
                        totalRecords: response.data.navigation.total
                    });
                }).catch(err => {
                    reject(err)
                })
        });
    },


    getStartupRelations: (entityId, entityType, limit = 6, page = 1) => {
        const url = `${API_URL_NEW}/${entityType}/${entityId}/startup-rel?limit=${limit}&page=${page}`;
        return new Promise((resolve, reject) => {
            axiosInstance.get(url).then(response => {
                resolve({
                    records: response.data.data,
                    totalRecords: response.data.navigation.total
                });
            }).catch((err) => reject(err));
        });
    },

}

export default startupApi;
