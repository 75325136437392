import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
// reusable components
import MagnittLoader from '../../../../micro-frontends/MagnittLoader';
import DonutChartWithLegend1 from '../../../../micro-frontends/d3Charts/DonutChartWithLegend1';
import DonutChartWithLegend3 from '../../../../micro-frontends/d3Charts/DonutChartWithLegend3';
import BarChart from '../../../../micro-frontends/d3Charts/BarChart';
import BarChart1 from '../../../../micro-frontends/d3Charts/BarChart1';
import BarChart2 from '../../../../micro-frontends/d3Charts/BarChart2';
import StackedBarWithLineChart from '../../../../micro-frontends/d3Charts/StackedBarWithLineChart';
import HorizontalStackedBarChartFundingByIndustry from '../../../../micro-frontends/d3Charts/HorizontalStackedBarChartFundingByIndustry';
import HorizontalStackedBarChartFundingByCountry from '../../../../micro-frontends/d3Charts/HorizontalStackedBarChartFundingByCountry';
// constants
import { ALLOWED_QUERY_OPERATOR, STACKED_BAR_LINE_COLOR, HORIZONTAL_STACKED_BAR_COLOR } from '../../../../lib/constants';
import { INVESTMENT_VEHICLES, NO_CHART_DATA, NO_CHART_DATA_SINGLE } from '../../../../lib/constants';
// helpers
import { amountFormatter, formatCurrency, formatDateCharts, prepareFilterParam } from '../../../../lib/helpers';
// api
import startupMarketMapApi from '../../../../apis/startupMarketMapApi';
// styles
import './StartupMarketMapCharts.css';


class StartupMarketMapCharts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            yearlyEvolutionData: {
                id: 'yearly-evolution',
                color_table: STACKED_BAR_LINE_COLOR,
                stack_types: ["amount_excluding_mega", "mega"],
                tooltip_keys: ['Year', 'Amount', 'Mega Deals (100M+)', '# of Deals', 'Total Funding'],
                legend_keys: ['Amount', 'Mega Deals (100M+)', '# of Deals'],
                yLabelLeft: "Amount",
                yLabelRight: "Deals (#)",
                x: (d) => d.year,
                y: (d) => d.amount,
                z: (d) => d.funding_type,
                height: 600
            },
            fundingDealsByCountryData: {
                id: 'funding-deal-country',
                color_table: HORIZONTAL_STACKED_BAR_COLOR,
                stack_types: ["amount_excluding_mega", "mega"],
                tooltip_keys: ['Country', 'Amount', 'Mega Deals (100M+)', 'Total Funding'],
                legend_keys: ['Amount', 'Mega Deals (100M+)'],
                xLabel: "Amount",
                yLabel: "Country",
                x: (d) => d.amount,
                y: (d) => d.country,
                z: (d) => d.funding_type,
                height: 700,
                width: 800,
            },
            industryFundingData: {
                id: 'funding-deal-industry',
                color_table: HORIZONTAL_STACKED_BAR_COLOR,
                stack_types: ["amount_excluding_mega", "mega"],
                tooltip_keys: ['Industry', 'Amount', 'Mega Deals (100M+)', 'Total Funding'],
                legend_keys: ['Amount', 'Mega Deals (100M+)'],
                xLabel: "Amount",
                yLabel: "Industry",
                x: (d) => d.amount,
                y: (d) => d.industry,
                z: (d) => d.funding_type,
                height: 700,
                width: 800,
            },
            donutIndustryDeals: {
                name: (d) => d.name,
                value: (d) => d.value,
                tooltip_keys: ['Industry', '# of Deals', '% Share'],
                format_values: false,
                text_display: 'Deals (#)'
            },
            donutIndustryValue: {
                name: (d) => d.name,
                value: (d) => d.value,
                tooltip_keys: ['Industry', 'Value of Deals', '% Share'],
                format_values: true,
                text_display: 'Total Funding'
            },
            fundingByGeographyData: {
                name: (d) => d.name,
                value: (d) => d.value,
                tooltip_keys: ['Country', 'Funding', '% Share'],
                format_values: true,
                text_display: 'Total Funding'
            },
            dealsByGeographyData: {
                name: (d) => d.name,
                value: (d) => d.value,
                tooltip_keys: ['Country', 'Value of Deals', '% Share'],
                format_values: false,
                text_display: 'Total Deals'
            },
            exitsByYearData: {
                id: "exits-year",
                color_table: ['#D31148'],
                tooltip_keys: ['Year', 'Exits'],
                legend_keys: ['Number of Exits'],
                yLabel: "Exits",
                x: (d) => d.year,
                y: (d) => d.acquisitions
            },
            exitsByIndustryData: {
                id: "exits-industry",
                color_table: ['#D31148'],
                tooltip_keys: ['Industry', 'Exits'],
                legend_keys: ['Number of Exits'],
                yLabel: "Exits",
                x: (d) => d.industry,
                y: (d) => d.acquisitions,
                height: 600
            },
            exitsByCountryData: {
                id: "exits-country",
                color_table: ['#D31148'],
                tooltip_keys: ['Country', 'Exits'],
                legend_keys: ['Number of Exits'],
                yLabel: "Exits",
                x: (d) => d.country,
                y: (d) => d.acquisitions,
                height: 600
            },

            showLoading: false,
            showYearlyEvolution: false,
            showIndustryFunding: false,
            showDonutIndustryValues: false,
            showDonutIndustryDeals: false,
            showFundingByGeography: false,
            showDealByGeography: false,
            showExitsByIndustry: false,
            showExitsByCountry: false,
            showExitsByYear: false,
            showFundingDealsByCountry: false,
            isSubIndustry: false,
            currentDate: '',
            fromDateYear: ''
        };
    }

    componentDidMount() {
        const { data } = this.props;
        const fromDateYear = (new Date()).getFullYear() - 4;
        const currentDate = formatDateCharts(new Date());

        this.setState({
            currentDate: currentDate,
            fromDateYear: fromDateYear,
            showLoading: true,
            filter: data.filter,
            limit: this.props.data.limit,
            paramFilters: [
                { key: 'verification_status', value: [1], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'investment_vehicle_id', value: [INVESTMENT_VEHICLES.equity], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
            ],
            dateFilter: [
                { key: 'date_raised', value: [`${fromDateYear}-01-01`, currentDate], op: ALLOWED_QUERY_OPERATOR.btw },
            ]
        }, () => {
            this.getChartsFilters();
        });
    }

    getChartsFilters() {
        const { paramFilters, dateFilter, filter } = this.state;

        const filterCopy = cloneDeep(filter);
        let customFilters;
        if (filterCopy.length > 0) {
            const locationIndex = filterCopy.findIndex((obj => obj.key === 'hq_id'));
            if (locationIndex !== -1)
                filterCopy[locationIndex].key = 'location_id';

            customFilters = prepareFilterParam(filterCopy);
        }

        let defaultFilters = prepareFilterParam(paramFilters);
        let filters = customFilters ? defaultFilters + `^,${customFilters}` : defaultFilters;

        // Date raised filter only for funding evolution and exits by year charts
        let dateRaisedFilter = prepareFilterParam(dateFilter);

        this.getYearlyFundingData(filters + `^,${dateRaisedFilter}`)
        this.getPrimaryIndustryFundingData(filters);
        this.getGeographyData(filters)

        let acquisitionFilters = prepareFilterParam(paramFilters.filter(item => item.key !== 'investment_vehicle_id'));
        let exitFilters = customFilters ? acquisitionFilters + `^,${customFilters}` : acquisitionFilters;

        this.getExitsByIndustryData(exitFilters)
        this.getExitsByCountryData(exitFilters)
        this.getExitsByYearData(exitFilters + `^,${dateRaisedFilter}`);
    }

    getYearlyFundingData(filters = "") {
        startupMarketMapApi.getYearlyFundingData(filters).then((response) => {
            if (!isEmpty(response) && response.records.length > 0) {
                this.getYearlyEvolutionData(response.records);
            } else {
                this.setState({ showYearlyEvolution: false })
            }
        })
    }

    getPrimaryIndustryFundingData(filters = "") {

        // handle the industry aggregation type according to the used filter
        // case: no filter => aggregate by primary industry
        // case: primary industry filter => aggregate by sub industry
        let fields = ['primary_industry_name', 'primary_industry'];
        if (filters.includes('primary_industry_filter')) {
            fields = ['sub_industry_name', 'sub_industry'];

            this.setState({
                isSubIndustry: true,
            });
        } else if (this.state.isSubIndustry) {
            this.setState({
                isSubIndustry: false,
            });
        }

        startupMarketMapApi.getPrimaryIndustryFundingData(filters, fields).then((response) => {
            if (!isEmpty(response) && response.records.length > 0) {
                const results = response.records.filter(element => element.total > 0);
                this.getIndustryFundingData(results.slice(0, 5));
                this.getDonutIndustryDeals(results);
                this.getDonutIndustryValue(results);
                this.setState({ showLoading: false });
            } else {
                this.setState({
                    showLoading: false,
                    showDonutIndustryValues: false,
                    showDonutIndustryDeals: false,
                    showIndustryFunding: false
                })
            }
        })
    }

    getYearlyEvolutionData(complete_data) {
        let amount_data = this.state.yearlyEvolutionData.stack_types.flatMap((funding_type) =>
            complete_data.map((d) => ({ year: d.year, funding_type, amount: d[funding_type] }))
        );
        let line_data = complete_data.map((d) => ([d.year, d.deals]));
        let total_data = complete_data.map((d) => ([d.year, d.total]));

        let tooltip_data = complete_data.map((d) => ([d.year, amountFormatter(d.amount_excluding_mega), amountFormatter(d.mega), formatCurrency(d.deals), amountFormatter(d.total)]));

        this.setState(prevState => ({
            yearlyEvolutionData: {
                ...prevState.yearlyEvolutionData,
                amount_data: amount_data,
                line_data: line_data,
                total_data: total_data,
                tooltip_data: tooltip_data
            },
            showYearlyEvolution: true,
        }));
    }

    getIndustryFundingData(complete_data) {
        const amount_data = this.state.industryFundingData.stack_types.flatMap((funding_type) =>
            complete_data.map((d) => ({ industry: d.industry, funding_type, amount: d[funding_type] }))
        ).reverse();
        let line_data = complete_data.map((d) => ([d.industry, d.deals]));
        let total_data = complete_data.map((d) => ([d.industry, d.total]));
        let tooltip_data = complete_data.map((d) => ([d.industry, amountFormatter(d.amount_excluding_mega), amountFormatter(d.mega), amountFormatter(d.total)]));

        this.setState(prevState => ({
            industryFundingData: {
                ...prevState.industryFundingData,
                amount_data: amount_data,
                line_data: line_data,
                total_data: total_data,
                tooltip_data: tooltip_data
            },
            showIndustryFunding: true,
        }));
    }

    getDonutIndustryDeals(industry) {
        this.setState(prevState => ({
            donutIndustryDeals: {
                ...prevState.donutIndustryDeals,
                data_share: industry,
                value_param: "deals",
                name_param: "industry"
            },
            showDonutIndustryDeals: true
        }));
    }

    getDonutIndustryValue(industry) {
        this.setState(prevState => ({
            donutIndustryValue: {
                ...prevState.donutIndustryValue,
                data_share: industry,
                value_param: "total",
                name_param: "industry"
            },
            showDonutIndustryValues: true
        }));
    }

    getFundingByGeographyData(chartData) {
        this.setState(prevState => ({
            fundingByGeographyData: {
                ...prevState.fundingByGeographyData,
                data_share: chartData,
                value_param: "total",
                name_param: "country"
            },
            showFundingByGeography: true
        }));
    }

    getDealsByGeographyData(chartData) {
        this.setState(prevState => ({
            dealsByGeographyData: {
                ...prevState.dealsByGeographyData,
                data_share: chartData,
                value_param: "deals",
                name_param: "country"
            },
            showDealByGeography: true
        }));
    }

    getGeographyData(filters = "") {

        if (filters.includes('location_id')) {
            this.setState({
                showDealByGeography: false,
                showFundingByGeography: false,
                showFundingDealsByCountry: false
            });

            return;
        }

        startupMarketMapApi.getGeographyData(filters).then((response) => {
            if (!isEmpty(response) && response.records.length > 0) {
                this.getDealsByGeographyData(response.records)
                this.getFundingByGeographyData(response.records)
                this.getFundingDealsByCountry(response.records.slice(0, 5))
                this.setState({ showLoading: false })
            } else {
                this.setState({
                    showDealByGeography: false,
                    showFundingByGeography: false,
                    showFundingDealsByCountry: false,
                    showLoading: false
                })
            }
        })
    }

    getExitsByYearData(filters = "") {

        startupMarketMapApi.getExitsByYearData(filters).then((response) => {
            if (!isEmpty(response) && response.records.length > 0) {
                let exitsData = response.records.map((d, i) => ([d.year, d.acquisitions]));

                this.setState(prevState => ({
                    exitsByYearData: {
                        ...prevState.exitsByYearData,
                        dataset: exitsData,
                        tooltip_data: exitsData
                    },
                    showExitsByYear: true,
                    showLoading: false
                }));
            } else {
                this.setState({
                    showExitsByYear: false,
                    showLoading: false
                })
            }
        })
    }

    getExitsByIndustryData(filters = "") {

        startupMarketMapApi.getExitsByIndustryData(filters).then((response) => {
            if (!isEmpty(response) && response.records.length > 0) {
                let exitsData = response.records.slice(0, 5).map((d, i) => ([d.industry, d.acquisitions]));

                this.setState(prevState => ({
                    exitsByIndustryData: {
                        ...prevState.exitsByIndustryData,
                        dataset: exitsData,
                        tooltip_data: exitsData
                    },
                    showExitsByIndustry: true,
                    showLoading: false
                }));
            } else {
                this.setState({
                    showExitsByIndustry: false,
                    showLoading: false
                })
            }
        })
    }

    getExitsByCountryData(filters = "") {

        startupMarketMapApi.getExitsByCountryData(filters).then((response) => {
            if (!isEmpty(response) && response.records.length > 0) {
                let exitsData = response.records.slice(0, 5).map((d, i) => ([d.country, d.acquisitions]));

                this.setState(prevState => ({
                    exitsByCountryData: {
                        ...prevState.exitsByCountryData,
                        dataset: exitsData,
                        tooltip_data: exitsData
                    },
                    showExitsByCountry: true,
                    showLoading: false
                }));
            } else {
                this.setState({
                    showExitsByCountry: false,
                    showLoading: false
                })
            }
        })
    }

    getFundingDealsByCountry(complete_data) {
        let amount_data = this.state.fundingDealsByCountryData.stack_types.flatMap((funding_type) =>
            complete_data.map((d) => ({ country: d.country, funding_type, amount: d[funding_type] }))
        ).reverse();
        let line_data = complete_data.map((d) => ([d.country, d.deals]));
        let total_data = complete_data.map((d) => ([d.country, d.total]));

        let tooltip_data = complete_data.map((d) => ([d.country, amountFormatter(d.amount_excluding_mega), amountFormatter(d.mega), amountFormatter(d.total)]));

        this.setState(prevState => ({
            fundingDealsByCountryData: {
                ...prevState.fundingDealsByCountryData,
                amount_data: amount_data,
                line_data: line_data,
                total_data: total_data,
                tooltip_data: tooltip_data

            },
            showFundingDealsByCountry: true
        }));
    }

    render() {

        const { showYearlyEvolution, showIndustryFunding, showDonutIndustryValues, showDonutIndustryDeals, showLoading, showFundingByGeography, showDealByGeography, showExitsByIndustry, showExitsByCountry, showExitsByYear, showFundingDealsByCountry } = this.state;

        // since there are multiple tabs, the charts are hidden by default when the page loads. this causes issues rendering the donut charts, as the chart must be visible for labels to be positioned correctly. to fix this, we only call donut charts when the charts tab is visible
        const { tabIsVisible } = this.props;

        const chartsHasNoData = !showYearlyEvolution && !showIndustryFunding && !showDonutIndustryValues && !showDonutIndustryDeals && !showLoading && !showFundingByGeography && !showDealByGeography && !showExitsByIndustry && !showExitsByCountry && !showExitsByYear && !showFundingDealsByCountry;

        if (chartsHasNoData) {
            return (
                <div className="no-chart-data">
                    <h6>{NO_CHART_DATA}</h6>
                </div>
            )
        }

        const noChartMessage = NO_CHART_DATA_SINGLE;

        return (
            <div id="network-map-cards" className="network-map-main">
                {this.state.showLoading && tabIsVisible &&
					<MagnittLoader data={{ height: "300px", background: "transparent" }} />
                }

                {!this.state.showLoading &&
					<div className="map-dashboard-wrapper">

					    <div className="map-dashboard-graph">
					        <div className="graph__title">Yearly Breakdown of Investment Amount and Deals ({this.state.fromDateYear} - Present)</div>
					        <div className="graph__chart">
					            {showYearlyEvolution ?
					                <StackedBarWithLineChart data={this.state.yearlyEvolutionData} /> :
					                <div className='no-chart-message'>
					                    <span>{noChartMessage}</span>
					                </div>
					            }
					        </div>
					    </div>


					    <div className="map-dashboard-graph">
					        <div className="graph__chart directory-sidebyside-chart donut-sidebyside">
					            <div className='sidebyside-chart-container-left'>
					                <div className="graph__title">Total Funding By Top 5 Countries</div>
					                {showFundingDealsByCountry ?
					                    <div className='horizontal-chart-container'>
					                        <HorizontalStackedBarChartFundingByCountry
					                            data={this.state.fundingDealsByCountryData}
					                        />
					                    </div>
					                    :
					                    <div className='no-chart-message'>
					                        <span>{noChartMessage}</span>
					                    </div>
					                }
					            </div>

					            <div className='sidebyside-chart-container-right donut-chart-container'>
					                <div className="graph__title">Deals - Share By Country</div>
					                {(showDealByGeography && tabIsVisible) ?
					                    <DonutChartWithLegend1 data={this.state.dealsByGeographyData} />
					                    :
					                    <div className='no-chart-message'>
					                        <span>{noChartMessage}</span>
					                    </div>
					                }
					            </div>
					        </div>
					    </div>

					    <div className="map-dashboard-graph">
					        <div className="graph__chart directory-sidebyside-chart donut-sidebyside">
					            <div className='sidebyside-chart-container-left'>
					                <div className="graph__title">Total Funding By Top 5 Industries</div>
					                {showIndustryFunding ?
					                    <div className='horizontal-chart-container'>
					                        <HorizontalStackedBarChartFundingByIndustry data={this.state.industryFundingData} />
					                    </div> :
					                    <div className='no-chart-message'>
					                        <span>{noChartMessage}</span>
					                    </div>
					                }
					            </div>

					            <div className='sidebyside-chart-container-right donut-chart-container'>
					                <div className="graph__title">Deals - Share By {this.state.isSubIndustry ? 'Sub' : 'Primary'} Industry</div>
					                {(showDonutIndustryDeals && tabIsVisible) ?
					                    <DonutChartWithLegend3 data={this.state.donutIndustryDeals} />
					                    :
					                    <div className='no-chart-message'>
					                        <span>{noChartMessage}</span>
					                    </div>
					                }
					            </div>
					        </div>
					    </div>

					    <div className="map-dashboard-graph">
					        <div className="graph__title">Yearly Exits ({this.state.fromDateYear} - Present)</div>
					        <div className="graph__chart">
					            {showExitsByYear ?
					                <BarChart data={this.state.exitsByYearData} /> :
					                <div className='no-chart-message'>
					                    <span>{noChartMessage}</span>
					                </div>
					            }
					        </div>
					    </div>

					    <div className="map-dashboard-graph">
					        <div className="graph__chart directory-sidebyside-chart bar-sidebyside">
					            <div className='sidebyside-chart-container-left'>
					                <div className="graph__title">Exits By Top 5 Countries</div>
					                {(showExitsByCountry && tabIsVisible) ?
					                    <BarChart1 data={this.state.exitsByCountryData} />
					                    :
					                    <div className='no-chart-message'>
					                        <span>{noChartMessage}</span>
					                    </div>
					                }
					            </div>
					            <div className='sidebyside-chart-container-right'>
					                <div className="graph__title">Exits By Top 5 Industries</div>
					                {(showExitsByIndustry && tabIsVisible) ?
					                    <BarChart2 data={this.state.exitsByIndustryData} />
					                    :
					                    <div className='no-chart-message'>
					                        <span>{noChartMessage}</span>
					                    </div>
					                }
					            </div>
					        </div>
					    </div>
					</div>
                }
            </div>
        )
    }
}

export default StartupMarketMapCharts;
