import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class DirTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // scroll position
            scrollPos: 1, // shouldn't be 0 at page load
            maxScroll: null,
            showLockedRows: false,
            // for locked content
            scrollFixWidth: 500,
            scrollFixHeight: 200,
        }
    }

    /* lifecycle methods */
    componentDidMount = () => {
        // initialize scroll width
        window.addEventListener('resize', this.scrollWidthFix);
        if (ReactDOM.findDOMNode(this.refs.scrollTable)) {
            this.setState({ scrollPos: ReactDOM.findDOMNode(this.refs.scrollTable).scrollLeft });
        }

        this.initiateTooltip();
    }

    componentDidUpdate = () => {
        this.scrollWidthFix();
        this.initiateTooltip();
    }

    /* end lifecycle methods */

    // Initiate tooltip
    initiateTooltip = () => {
        if (typeof window.jQuery !== 'undefined') {
            $(function () {
                $('[data-toggle="tooltip"]').tooltip()
            })
        }
    }

    // fix scroll issues in locked content and table collapsible row
    scrollWidthFix = () => {

        const { scrollFixWidth } = this.state;
        const { isFundingView } = this.props;
        const tableIndex = (isFundingView ? 1 : 0)

        const element = document.getElementsByClassName("table-responsive");
        if (element && element[tableIndex]) {
            if (scrollFixWidth !== element[tableIndex].clientWidth) {
                this.setState({ scrollFixWidth: element[tableIndex].clientWidth })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.scrollWidthFix);
    }

    makeRow = (rowData) => {
        let row = [];
        for (var i in rowData) {
            for (var key in rowData[i]) {
                if (key !== "id" && key !== "code" && key !== "element_name") {
                    const data = rowData[i][key];
                    if (data === "paidData") {
                        row.push(
                            <td data-rowtype={key} key={key}>
                                <a className="btn btn-main-outline btn-sm" href="/pricing" target="_blank" rel="noopener noreferrer">
                                    <span className="d-flex align-items-center">
                                        <i className="fas fa-lock"></i><span>Unlock</span>
                                    </span>
                                </a>
                            </td>
                        )
                    } else {
                        row.push(<td data-rowtype={key} key={key}><div>{data}</div></td>)
                    }
                }
            }
        }
        return row;
    }

    scrollTable = (scrollType) => {
        const scrollVal = 80; // scroll distance
        const element = ReactDOM.findDOMNode(this.refs.scrollTable);
        if (!element) { return }

        const maxScroll = element.scrollWidth - element.clientWidth;
        this.setState({ maxScroll })
        this.setState({ scrollPos: element.scrollLeft })

        if (scrollType === 1) {
            // go left
            element.scrollLeft -= scrollVal;
            this.setState({ scrollPos: (element.scrollLeft - scrollVal) })
        } else if (scrollType === 2) {
            // go right
            element.scrollLeft += scrollVal;
            this.setState({ scrollPos: (element.scrollLeft + scrollVal) })
        }
        // didn't use this.setState({ scrollPos: element.scrollLeft }) - it doesn't reflect the latest scroll
    }

    renderTableRows = () => {

        const { content } = this.props;
        const rowData = content.rows;

        const tableRows = rowData.map((row, j) => {
            const tableDataCells = this.makeRow(row);
            return (<tr key={j}>{tableDataCells}</tr>)
        })
        return tableRows;

    }

    render() {

        const { content } = this.props;
        const { scrollPos, maxScroll } = this.state;

        return (
            <div className="directory-table">
                <div className="table-responsive " ref="scrollTable">
                    <table className="table">

                        <thead>
                            <tr>
                                {content.header &&
									content.header.map((item, i) => {
									    return (
									        <th key={i}>
									            {i === 1 && scrollPos > 0 &&
													<div className="table-scroll" onClick={() => this.scrollTable(i)}></div>
									            }
									            {i === 2 && scrollPos <= maxScroll &&
													<div className="table-scroll" onClick={() => this.scrollTable(i)}></div>
									            }
									            {item}
									        </th>
									    )
									})}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
