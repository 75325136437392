import { sharedHelpers } from 'shared-helpers';
import { sharedQueryParams } from 'shared-helpers';
import cloneDeep from 'lodash/cloneDeep';

// from shared-helpers
export const amountFormatter = sharedHelpers.formatValue;
export const formatDateCharts = sharedHelpers.formatDate;
export const formatCurrency = sharedHelpers.formatCurrency;
export const getItemWebUrl = sharedHelpers.getItemWebUrl;

export const prepareFilterParam = sharedQueryParams.prepareFilterParam;
export const prepareSelectParam = sharedQueryParams.prepareSelectParam;
export const prepareSortParam = sharedQueryParams.prepareSortParam;

/**
 * Method to prepare pagination query param syntax
 *
 * @param {Array}   params   Array of objects to perform pagination
 * @example[{
     key: 'limit',
     value: '10'
 }, {
     key: 'page',
     value: '1'
 }]
 * @returns {String}            Returns a prepared query param string
 */
export const preparePaginationParams = (params = []) => {
    // Default pagination param
    if (params.length < 1) {
        return 'limit=20&page=1'
    }
    const parsedParamsArray = params.map(param => `${param.key}=${param.value}`)
    return parsedParamsArray.join('&')
}


/**
 * remove duplicate objects from an array
 * @param {Array} data
 */
export const removeDuplicateData = (data) => {
    let seen = new Set();
    let uniqueData = data.filter((item) => {
        let key = JSON.stringify(item);
        let duplicate = seen.has(key);
        seen.add(key);
        return !duplicate;
    });

    return uniqueData
}

/**
 * Make changes in entity type appropriately to use it in URL, e.g. USER -> people, STARTUP -> startups
 * @param {String} type entity type
 */
export const prepEntityTypeLower = (type) => {
    let strReturn = "";

    if (type) {
        type = type.toLowerCase();

        if (type === "user") {
            strReturn = 'people';

        } else {
            strReturn = type + 's';
        }
    }

    return strReturn;
}


/* format employee count and return a range string (such as '50 - 100') */
export const formatEmployeeCount = (data) => {
    let str = "N/A";
    if (data.min_num_emp && data.max_num_emp) {
        str = `${data.min_num_emp} - ${data.max_num_emp}`;
    } else if (data.min_num_emp && data.max_num_emp == 0) {
        str = `${data.min_num_emp}+`;
    }
    return (str);
}

/* format date founded and return in format MM/YYYY */
export const formatDateFounded = (data) => {
    let date = "N/A";
    // newDate(null) returns 1970-01-01
    // see https://stackoverflow.com/questions/49339980/why-new-datenull-is-returning-this-date-1970-01-01t000000-000z
    if (data.date_founded && data.date_founded !== '1970-01-01 00:00:00') {
        date = data.date_founded.substr(5, 2) + '/' + data.date_founded.substr(0, 4);
    }
    return (date);
}

/*
	format boolean integer values where 1 = true, 0 = false and null = undefined / N/A
	this format is used for revenue_generating, is_active, etc.
*/
export const formatBooleanInteger = (val) => {
    let str = "N/A";
    if (val && val == 1) {
        str = 'Yes';
    } else if (val == 0) {
        str = 'No';
    }
    return (str);
}

export const prepareData = (data , isInvestorData) => {
    const clonedData = cloneDeep(data);
    const entityData = clonedData?.map((item) => {
        const data = item?.entity_data || item?.investor_detail_data;


        if (isInvestorData && item?.investor_detail_data) {
            for (const obj of data) {
                if (!obj?.logo_data && obj.logo_path) {
                    obj.logo_data = {
                        path: obj.logo_path
                    }
                }

                obj.name = obj.investor_name
            }

        }

        return data
    });

    const tempData = entityData?.flat()

    if (tempData?.length > 0) {

        return removeDuplicateData(tempData);
    }

    return tempData;
}
