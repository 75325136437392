import axiosInstance from './axiosInstance';
// util
import { API_URL_NEW, BIGQUERY_URL } from '../lib/endpoints';
import { prepareFilterParam, prepareSelectParam } from '../lib/helpers';

const ecosystemMarketMapApi = {
    getEcosystemDirectoryLandscape: (path, fields, filters, limit, page, sort) => {
        const url = `${BIGQUERY_URL}/investors/${path}?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axiosInstance.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => console.error(err));
        });
    },

    getEcosystemLandscapePagination: (path, id, fields, filters, limit, page, sort) => {
        const url = `${BIGQUERY_URL}/investors/${path}/pagination/${id}?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axiosInstance.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve(response.data.data[0].investor_detail_data);
                } else {
                    resolve([]);
                }
            }).catch((err) => console.error(err));
        });
    },

    getInvestors: (selectableFields, filter, limit , page, sort) => {
        const arSelect = [
            'id',
            'name',
            'ecosystem_type',
            'code',
            'owned_by',
            'logo_data',
            'description',
            'type_data',
            'hq_data',
            'number_of_investments',
            'estimated_deployed_capital',
            'number_of_funds',
            'number_of_startup_investments',
            ...selectableFields
        ];
        const fields = prepareSelectParam(arSelect);
        const filters = prepareFilterParam(filter);

        return new Promise((resolve, reject) => {
            let url = `${API_URL_NEW}/ecosystem/?fields=${fields}&filters=${filters}^,status^:1&limit=${limit}&page=${page}&sort=${sort}`
            axiosInstance.get(url)
                .then(response => {
                    if (response.data.status === 200) {
                        let investors = response.data.data;
                        let count = response.data.navigation.total

                        resolve({
                            investors,
                            count
                        })
                    } else {
                        reject(response)
                    }
                }).catch(err => reject(err))
        })
    },




}

export default ecosystemMarketMapApi;
