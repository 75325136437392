import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

// api
import peopleApi from '../../../../../apis/peopleApi.js';

// helpers
import { getItemWebUrl } from '../../../../../lib/helpers';

// reusable
import SocialTab from './SocialTab.js';


const PeopleSection = (props) => {

    const [state, setState] = useState({
        peopleData: [],
        isLoading: false,
        endCount: 4,
        page: 1,
        hasAccess: false,
        totalDBData: 0,
        focusedEntityId: null,
        peopleContent: []
    });

    const { peopleData, isLoading, endCount, page, totalDBData, focusedEntityId, peopleContent } = state;
    const { isDirSlider, entityType, entityId } = props;

    const changeBy = 4;
    const startCount = 0;
    const hasPeopleData = (peopleData && peopleData.length > 0);

    const updateState = (newState) => {
        setState({ ...state, ...newState });
    }

    useEffect(() => {
        updateState({ focusedEntityId: entityId });
        loadData()
    }, []);

    useEffect(() => {
        if (focusedEntityId !== entityId) {
            updateState({ focusedEntityId: entityId, peopleData: [], totalDBData: null });
            loadData();
        }
    }, [entityId]);

    // update displayed people list when people data changes
    useEffect(() => {
        preparePeopleContent();
    }, [peopleData])

    const loadData = (page = 1, endCount = 4) => {
        updateState({ isLoading: true });

        const limit = 4;
        const recordLimit = props.limit ? props.limit : limit;
        peopleApi.getPeopleTeamData(entityId, entityType, page, recordLimit).then(

            response => {
                let tempResponse = []
                if (page === 1) {
                    tempResponse = response.data
                } else {
                    tempResponse = [...peopleData, ...response.data]
                }

                // prepare team item
                const sortedData = tempResponse.sort((a, b) => {
                    a.emailViewed = false;
                    b.emailViewed = false;
                    return b.is_founder - a.is_founder
                });

                updateState({
                    peopleData: sortedData,
                    totalDBData: response.navigation.total,
                    endCount,
                    page,
                    isLoading: false
                });
            }
        );
    };

    const handleShowMore = async () => {
        updateState({ isLoading: true });
        const newEndCount = endCount + changeBy;

        if (totalDBData > peopleData.length) {
            return loadData(page + 1, newEndCount)
        }

        updateState({ endCount: newEndCount, isLoading: false });
    };

    const viewContact = (i) => {
        peopleData[i].emailViewed = true;

        updateState({ peopleData });
        preparePeopleContent();
    };

    const preparePeopleContent = () => {
        let peopleList = [];
        if (hasPeopleData) {
            for (const personIndex in peopleData) {
                if ((personIndex >= startCount) && (personIndex <= endCount - 1)) {

                    const person = peopleData[personIndex];
                    let image;

                    let personRole = 'N/A';
                    if (!isEmpty(person.team_role_data)) {
                        personRole = person.team_role_data.name;
                    }

                    let path = "";
                    let name = "";
                    if (!isEmpty(person.people_data)) {
                        const personData = person.people_data;
                        name = `${personData.first_name} ${personData.last_name}`;
                        path = 'https://magnitt.com' + `/${getItemWebUrl("people", personData.id, personData.code)}`;

                        let subName = name.trim().slice(0, 1);
                        if (personData.profile_pic_id && personData.profile_pic) {
                            image = <div className="image" style={{ backgroundImage: `url('${encodeURI(personData.profile_pic)}')` }}></div>;
                        } else {
                            image = <div className="image"><h2>{subName}</h2></div>;
                        }

                        let emailValue;
                        const hasEmail = (personData.email) || false;
                        if (hasEmail) {
                            emailValue = personData.email.toLowerCase();
                        }
                        const showLockedEmail = (hasEmail);

                        let containerClass = "col-md-6 col-12";
                        let target_type = "";
                        if (isDirSlider) {
                            containerClass = "col item-in-slider";
                            target_type = "_blank";
                        }
                        const social_media_links = {
                            twitter: personData.twitter,
                            linkedin: personData.linkedin,
                            facebook: personData.facebook
                        };

                        peopleList.push(
                            <div className={`${containerClass} team-section-item`} key={person.id}>
                                <div>
                                    <a href={path} target={target_type}>
                                        {image}
                                    </a>
                                </div>
                                <div>
                                    <h3>
                                        <a href={path} target={target_type}>
                                            {name}
                                        </a>
                                    </h3>
                                    <p>{personRole}   {person.is_founder == true && entityType === 'startup' && <span className="badge badge-pill founder-badge">Founder</span>}</p>
                                    <div className="team-section-contact">
                                        <p>
                                            {(showLockedEmail && person.emailViewed) &&
												<a href={`mailto:${emailValue}`} className="team-viewed-email">
												    {emailValue}
												</a>
                                            }

                                            {(showLockedEmail && !person.emailViewed) &&
												<>1 email address found</>
                                            }

                                            {(hasEmail && !person.emailViewed) &&
                                            <a className="btn btn-main btn-sm" onClick={() => viewContact(personIndex)}>
												    <i className="fas fa-eye"></i>
													View
                                            </a>
                                            }
                                        </p>
                                        <SocialTab object={social_media_links} size={"small"} />
                                    </div>
                                </div>
                            </div>
                        );

                    }

                }
            }
        }
        updateState({ peopleContent: peopleList });
    }

    const renderPeopleContent = () => {

        let countString = null;
        if (peopleData.length > endCount - 1) {
            countString = `(Showing ${(endCount)} out of ${totalDBData})`;
        } else {
            countString = `(Showing ${(totalDBData)} out of ${totalDBData})`;
        }

        if (!hasPeopleData) {
            return null;
        } else {
            return (
                <>
                    <hr />
                    <div className="block team team-section">
                        <div className="title d-flex">
                            <h2>Team Members</h2>
                            {(countString) &&
								<span style={{ marginLeft: 5, color: '#adb1b5', fontSize: 15 }}>
								    {countString}
								</span>
                            }
                        </div>

                        <div className="row">
                            {peopleContent}
                        </div>

                        {peopleData.length < totalDBData &&
                        <div className="d-flex justify-content-center mb-0">
							    <a className="btn btn-main btn-sm" onClick={handleShowMore}>
							        {isLoading ?
							            <i className="fas fa-circle-notch fa-spin mr-2"></i>
							            :
							            <i className="fas fa-arrow-circle-down mr-2"></i>
							        }
									Show More
							    </a>
                        </div>
                        }
                    </div>
                </>
            );
        }
    }

    return renderPeopleContent()
}

export default PeopleSection;
