import { sharedConstants } from 'shared-helpers';

// from shared-helpers
export const ALLOWED_QUERY_OPERATOR = sharedConstants.ALLOWED_QUERY_OPERATOR;

export const ENTITY_STATUS = {
    claimedOrAdded: 1, // Claimed or added by MAGNiTT team
    invited: 2 // Invited by a user
}

// directory pagination
export const RECORD_COUNT_OPTIONS = [10, 25, 50, 100];

// Chart colors
export const STACKED_BAR_LINE_COLOR = ['#D31148', '#2C373B', '#FF8811'];
export const HORIZONTAL_STACKED_BAR_COLOR = ['#D31148', '#2C373B'];

const investmentVehicles = {
    development: {
        equity: 6819
    },
    production: {
        equity: 6762
    }
};

const location = {
    development: {
        bahrain: 5674
    },
    production: {
        bahrain: 5674
    }
};

export const INVESTMENT_VEHICLES = investmentVehicles[process.env.NODE_ENV];
export const LOCATION_IDS = location[process.env.NODE_ENV];

// chart data - no data for a single chart
export const NO_CHART_DATA_SINGLE = 'Data is not available for this chart within the current filters';

// chart data - no data for all charts on a page
export const NO_DATA = 'There is not enough data to display this section, as your selected filters are too narrow'
export const NO_CHART_DATA = 'There is not enough data to display the charts, as your selected filters are too narrow';


