import React from 'react';

// reusable
import DirectoryPaging from './TablePaging';
import MagnittLoader from '../../../../../../micro-frontends/MagnittLoader';


/* this component displays magnitt loader / pagination / no results msg as needed */

const DirectoryFooter = (props) => {

    const { data, showTestData, user, count } = props;

    if (showTestData) {
        return (null);
    }

    // magnitt loader
    else if (data === false) {
        return (
            <MagnittLoader data={{ height: "300px", background: "#FFF" }} />
        )
    }

    // pagination
    else if (data.length <= count) {
        return (
            <DirectoryPaging
                // funcs
                filterValues={props.filterValues}
                setState={props.setState}
                // passed values
                page={props.page}
                count={count}
                limit={props.limit}
                loadingDirPage={props.loadingDirPage}
                tempFilter={props.tempFilter}
            />
        )
    }

    else {
        return (null);
    }

}

export default DirectoryFooter;
