import React, { Component } from 'react';

// reusable
import SimpleListSection from './SliderComponents/SimpleListSection';
import SocialTab from './SliderComponents/SocialTab';
import EntityLogo from './SliderComponents/EntityLogo';
import CompanyContactButton from './SliderComponents/CompanyContactButton';
// helpers
import { getItemWebUrl } from '../../../../lib/helpers';
import * as sliderHelpers from './helpers/sliderHelpers';

//constants
import PeopleSection from './SliderComponents/PeopleSection';
import './DirectorySlider.scss';


// set default values in case the calculation fails
const INITIAL = {
    SLIDER_WIDTH: 400,
    SLIDER_OFFSET: -900,
}
const MAX_SLIDER_WIDTH = 760;

// max display value for team + inv
const MAX_TO_DISPLAY = 4;



class DirectorySlidingContent extends Component {

    constructor(props) {
        super(props);
        const entity = props.focusedTabName.includes('startup') ? 'startup' : 'investor';
        this.state = {
            sliderWidth: INITIAL.SLIDER_WIDTH,
            sliderOffset: INITIAL.SLIDER_OFFSET,
            current_index: null,
            // data
            selectedEntity: null,
            entityType: entity,
            backgroundInfo: null,
            documentIndexObj: {
                startupsLandScapeTab: 0,
                startupsTableTab: 1,
                investorsLandScapeTab: 2,
            },
            selectors: {
                startupsLandScapeTab: ".directory-table",
                startupsTableTab: ".startups-network-map-cards",
                investorsLandScapeTab: ".investor-network-map-cards"
            }
        }

    }

    // it's handled this way as the trigger link is saved in a component that's sent to DirTable.js
    // so it's not straightforward to use onClick/setState

    // we are listening to directory-table so clicks on new rows can be captured as well
    watchSliderTrigger = () => {
        const { selectors } = this.state;

        Object.values(selectors).forEach((selector) => {
            const element = document.querySelector(selector);
            if (element) {
                element.addEventListener("click", this.triggerSlider, false);
            }
        });
    }

    triggerSlider = (e) => {
        const targetData = e.target.dataset; // get data-props
        const { viewEntityType } = this;
        const { documentIndexObj } = this.state;
        const { entityData, focusedTabName } = this.props;   // entity data of rows visible in the directory
        const index = documentIndexObj[focusedTabName];

        if (targetData.action === "expand-slider") {

            document.getElementsByClassName("slider-backdrop")[0].style.display = "block";

            entityData[focusedTabName].map((entity, i) => {
                if (entity.id == targetData.entityid) {

                    let type = viewEntityType();
                    if (focusedTabName.includes('investor')) {
                        type = targetData.ecosystemtype;
                    }

                    const companyInfo = sliderHelpers.getCompanyInfo(type, entity);
                    let backgroundInfo = null

                    this.setState({
                        selectedEntity: entity,
                        current_index: i,
                        companyInfo,
                        backgroundInfo,
                        entityType: type
                    },
                    () => {
                        this.callAdditionalFields(entity.id);
                        sliderHelpers.expandSlider(index);
                    });

                }
            });
        }
    }

    /* necessary to do it this way so we can get the data passed with the trigger element */
    sliderButtons = (type) => {
        const { current_index } = this.state;
        const { focusedTabName, entityData } = this.props;

        let clickedItemIndex = type === "prev" ? current_index - 1 : current_index + 1;

        const tempEntityData = entityData[focusedTabName][clickedItemIndex];

        let elementId = `${tempEntityData.name}_${tempEntityData.id}_${focusedTabName}`;

        document?.getElementById(elementId)?.click();
        // reset company contact
        $('#companyEmailPopover').popover('hide');
    }

    callAdditionalFields = (entityId) => {
        const { selectedEntity, entityType } = this.state;
        const { viewEntityType } = this;
        sliderHelpers.getDataForSlider(entityId, entityType).then(
            response => {
                let tempData = { ...selectedEntity, ...response };
                let backgroundInfo = null;
                let companyInfo = null;

                companyInfo = sliderHelpers.getCompanyInfo(viewEntityType(), tempData);

                this.setState({
                    selectedEntity: tempData,
                    companyInfo,
                    backgroundInfo
                });
            }
        )
    }

    componentDidUpdate = () => {
        const { sliderWidth } = this.state;

        if (sliderWidth === INITIAL.SLIDER_WIDTH) {
            this.calculateWidth();
        }

        this.watchSliderTrigger();
    }

    componentWillUnmount = () => {
        const { selectors } = this.state;

        Object.values(selectors).forEach((selector) => {
            const element = document.querySelector(selector);
            if (element) {
                element.removeEventListener('click', this.triggerSlider, false);
            }
        });
    }

    // calculate slider width
    calculateWidth = () => {
        const elementIndex = 0;

        const frameElement = document?.getElementsByClassName("preview-market-map")[elementIndex];

        const frameElementLoaded = frameElement?.clientWidth > 0;

        if (!frameElementLoaded) {
            return;
        }

        const dirElement = document?.getElementsByClassName("directory")[0];

        // mobile, small tablet (full width)
        if (window.innerWidth <= 900) {
            this.setState({
                sliderWidth: '100%',
                sliderOffset: '-100%'
            })
        }
        // desktop, large tablet (lines up with the end of the 'name' column)
        else if (frameElement && dirElement) {

            const frameWidth = frameElement.clientWidth;

            const pagePadding = window.getComputedStyle(dirElement, null).getPropertyValue('padding-left');

            let sliderWidth = frameWidth + parseFloat(pagePadding);


            if (sliderWidth > MAX_SLIDER_WIDTH) {
                sliderWidth = MAX_SLIDER_WIDTH;
            }
            this.setState({ sliderWidth, sliderOffset: -sliderWidth })
        }
    }

    viewEntityType = () => {
        const { focusedTabName } = this.props;
        if (focusedTabName.includes('startup')) {
            return 'startup';
        } else if (focusedTabName.includes('investor')) {
            return 'investor';
        }
    }

    render() {
        /* note:
		   - fields already available in the directory will be shared with this component
		   - additional fields will be called on componentDidMount
		*/

        // constants
        const { sliderWidth, sliderOffset } = this.state;
        const sliderStyle = {
            width: sliderWidth,
            right: sliderOffset,
        }

        // from state and props
        const { selectedEntity, current_index, companyInfo, entityType } = this.state;
        const { viewEntityType } = this;
        const { user, entityData, focusedTabName } = this.props;
        const data = selectedEntity; // for readability

        const page_source = 'HUBSPOT_FORM_SOURCE.dir_locked_contacts';

        let profile_url = "";
        if (data) {
            const strEntityType = entityType + "s";
            profile_url = `https://magnitt.com/${getItemWebUrl(strEntityType, data.id, data.code)}`;
        }

        const entityOverviewInfo = () => {
            return companyInfo && companyInfo.overview.length > 0 ? companyInfo.overview : [];
        }

        const entityInfoData = () => {
            return companyInfo && companyInfo.data.length > 0 ? companyInfo.data : [];
        }

        return (
            <>
                <div className="slider-container sidebar" data-expanded="false" style={sliderStyle}>

                    {/* conditional rendering of 'data' must be inside .slider-container, not outside
                    otherwise it causes issues with the slider opening animation */}

                    {data && <>

                        <div className="slider-header-section">

                            <div className="slider-btn-box">
                                {/* next and prev btns */}
                                {(current_index > 0) ?
                                    <a onClick={() => this.sliderButtons("prev")}>
                                        <i className="fas fa-chevron-circle-left"></i>
                                    </a>
                                    :
                                // disabled btn
                                    <i className="fas fa-chevron-circle-left disabled"></i>
                                }
                                {(current_index < (entityData[focusedTabName].length - 1)) ?
                                    <a onClick={() => this.sliderButtons("next")}>
                                        <i className="fas fa-chevron-circle-right"></i>
                                    </a>
                                    :
                                // disabled btn
                                    <i className="fas fa-chevron-circle-right disabled"></i>
                                }

                                <a onClick={() => sliderHelpers.closeSlider(user)}>
                                    <i className="fas fa-times-circle"></i>
                                </a>
                            </div>

                            <div className="row">

                                <div className="col-2">
                                    <EntityLogo data={data} entityType={entityType} size={"responsive"} />
                                </div>

                                <div className="col-10">

                                    <a className="slider-header" href={profile_url} target="_blank" rel="noopener noreferrer">
                                        {data.name}
                                    </a>

                                    <div className="slider-text" style={{ fontSize: '14px', lineHeight: 1.5 }}>

                                        {entityOverviewInfo().map((data, i) =>
                                            <div key={`${data.id}_${i}`}>
                                                <span style={{ fontSize: '14px' }}>{data.field}: </span>
                                                {data.value}
                                            </div>)
                                        }

                                        <CompanyContactButton
                                            user={user}
                                            id={data.id}
                                            entityType={entityType}
                                            setState={this.props.setState}
                                        />

                                    </div>
                                    <SocialTab object={data} size="small" />
                                </div>

                            </div>
                        </div>

                        <hr />

                        <div className="slider-scrollable">

                            <h3>Company Information</h3>
                            <div className="row">
                                <div className="col slider-text slider-detail-text"
                                    style={{ fontSize: '14px' }}
                                >
                                    {entityInfoData().map((data, i) =>
                                        <div key={`${data.id}_${i}`}>
                                            <div className="circle-icon"><i className={data.icon}></i></div>
                                            <div>
                                                <span style={{ fontSize: '14px' }}>{data.field}: </span>
                                                {data.value}
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div className="content-block">
                                <div className="right-side">
                                    <PeopleSection
                                        entityType={entityType}
                                        entityId={data.id}
                                        isDirSlider={true}
                                        limit={MAX_TO_DISPLAY}
                                        user={user}
                                        setState={this.props.setState}
                                        page={page_source}
                                    />
                                </div>
                            </div>

                            <div className="content-block">
                                <div className="right-side">
                                    <SimpleListSection
                                        dataType={viewEntityType() === 'startup' ? 'investor' : 'startup-relation'}
                                        entityId={data.id}
                                        entityType={entityType}
                                        isDirSlider={true}
                                        limit={MAX_TO_DISPLAY}
                                        user={user}
                                        setState={(s, c) => { this.setState(s, c) }}
                                    />
                                </div>
                            </div>

                            {<div className="slider-button">
                                <a className="btn btn-main" href={profile_url} target="_blank" rel="noopener noreferrer">
									View Full Profile
                                </a>
                            </div>}

                        </div>
                    </>}
                </div >

                <div
                    className="slider-backdrop"
                    id="slider-overlay"
                    data-dismiss="modal"
                    onClick={() => sliderHelpers.closeSlider(user)}
                ></div>
            </>
        )
    }

}

export default DirectorySlidingContent;
