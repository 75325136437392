import React, { Component } from 'react';

export default class SocialTab extends Component {
    render() {

        const { object, type, size } = this.props;
        const socialKeys = ["website", "twitter", "facebook", "linkedin", "ios_app_url", "android_app_url"];

        let socialLinks = {};
        if (object !== null && Object.keys(object).length) {
            for (let i in socialKeys) {

                let socialVal = object[socialKeys[i]];
                if (socialVal) {
                    let isInvalid = false;
                    if (socialVal[0] === "@") {
                        isInvalid = true;
                    }

                    if ((socialVal.substring(0, 3) === "www") ||
                        ((socialVal.indexOf("www") === -1) && (socialVal.indexOf("http") === -1))) {
                        socialVal = "http://" + socialVal;
                    }

                    if (!isInvalid) {
                        socialLinks[socialKeys[i]] = socialVal;
                    }
                }
            }
        }

        const hasSocialMedia = (Object.keys(socialLinks).length > 0);

        let className = "social-tab justify-content-center ";
        if (type == "sice") {
            className += "mt-4";
        } else {
            className += "mt-0";
        }
        if (size !== "small") {
            className += " social-tab-lg"
        }

        let content = null;
        if (hasSocialMedia) {
            content =
                <>
                    {type == "sice" && <hr style={{ marginTop: 30 }} />}
                    <div className={className} style={this.props.style}>
                        {Object.entries(socialLinks).map(([k, v, index]) => {
                            return (
                                <a href={v} target="_blank" rel="noopener noreferrer" key={index + "_" + k}>
                                    {k == "twitter"  && <i className="fab fa-twitter"></i>}
                                    {k == "facebook" && <i className="fab fa-facebook-f"></i>}
                                    {k == "linkedin" && <i className="fab fa-linkedin-in"></i>}
                                    {k == "website"  && <i className="fas fa-globe"></i>}
                                    {k == "android_app_url" && <i className="fab fa-android"></i>}
                                    {k == "ios_app_url" && <i className="fab fa-apple"></i>}
                                </a>
                            );
                        })}
                    </div>
                </>
        }

        return content;

    }
}
