import axiosInstance from './axiosInstance';

import { API_URL_NEW } from '../lib/endpoints';
import { prepareSelectParam } from '../lib/helpers';

const peopleApi = {
    getPeopleTeamData: (entityId, entityType, page, limit = "") => {
        const fields = prepareSelectParam([
            'id',
            'entity_id',
            'entity_data',
            'is_founder',
            'people_id',
            'people_data',
            'team_role_id',
            'team_role_data',
        ]);
        let url = `${API_URL_NEW}/${entityType}/${entityId}/people-team?fields=${fields}&limit=${limit}&page=${page}`;

        return new Promise((resolve, reject) => {
            axiosInstance.get(url).then((response) => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    },

};

export default peopleApi;
