import React from 'react';
import isEmpty from 'lodash/isEmpty';

// helpers
import { getItemWebUrl } from '../../../../../lib/helpers';


const EntityLogo = (props) => {

    const entity = props.data;
    const entity_type = props.entityType.toLowerCase();
    const isResponsive = (props.size === "responsive");
    const strEntityType = entity_type + "s";
    const isFund = entity_type === "fund";
    let profile_url = `https://magnitt.com/${getItemWebUrl(strEntityType, entity.id, entity.code)}`;

    if (entity_type == 'user') {
        profile_url = `https://magnitt.com/people/${entity.id}/${entity.code}` // user profile now has entityType 'people' in URL
    }

    if (!isEmpty(entity.logo_data) && entity.logo_data.path) {
        const path = encodeURI(entity.logo_data.path);
        const style = {
            backgroundImage: `url('${path}')`
        }
        return (
            <a className='entityLogo' style={style} data-responsive={isResponsive} href={profile_url} target="_blank" rel="noopener noreferrer"></a>
        )
    } else if (isFund) {
        return (
            <>
                <div className='entityLogo' data-responsive={isResponsive}>
                    <h2>{entity.name ? entity.name.substr(0, 1).toUpperCase() : '?'}</h2>
                </div>
            </>)
    } else {
        return (
            <>
                <a className='entityLogo' data-responsive={isResponsive} href={profile_url} target="_blank" rel="noopener noreferrer">
                    <h2>{entity.name ? entity.name.substr(0, 1).toUpperCase() : '?'}</h2>
                </a>
            </>

        )
    }

}

export default EntityLogo;
