import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
// helpers
import { amountFormatter, prepEntityTypeLower, getItemWebUrl, removeDuplicateData } from "../../../../../lib/helpers";
// apis
import startupApi from '../../../../../apis/startupApi';


export default class SimpleListSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",

            // show more
            limit: 6,
            page: 1,
            count: 0,
            data: [],
            isLoading: false,
        }
    }

    componentDidMount = () => {
        this.prepTitle();
        this.loadData();
    }

    componentDidUpdate = (prevProps) => {
        const { entityId } = this.props;

        if ((entityId !== prevProps.entityId)) {
            this.setState({ data: [] });
            this.loadData();
            this.prepTitle();
        }
    }

    loadData = () => {
        let { limit, page } = this.state;
        const { entityId, entityType, dataType } = this.props;

        let getData;
        if (dataType === "investor") {
            getData = startupApi.getInvestors;
        } else if (dataType === "startup-relation") {
            getData = startupApi.getStartupRelations;
        }

        limit = 4;

        getData(entityId, entityType, limit, page).then(
            response => {
                return this.setState({
                    data: response.records,
                    count: response.totalRecords,
                    isLoading: false,
                })
            }
        );
    }

    prepTitle = () => {
        const { dataType } = this.props;
        let title = "";
        if (dataType === "investor") {
            title = "Investors";
        } else {
            title = 'Startup Relations';
        }
        this.setState({ title });
    }

    handleShowMore = () => {
        const { limit, page, count } = this.state;
        let tempPage = (parseInt(page, 10));

        // Only increment the page if records exists
        if (count > (limit * tempPage)) {
            this.setState({ isLoading: true });
            tempPage = page + 1;
            this.setState({ page: tempPage },
                () => { this.loadData(); }
            );
        }
    }

    render() {

        const { dataType } = this.props;
        const { title, count, data } = this.state;
        const showingCount = data ? data.length : 0;

        // no data
        if (data.length < 1) {
            return null;
        }

        return (
            <>
                <hr />
                <div className="block team simple-detail-section">

                    <div className="title d-flex">
                        <h2>{title}</h2>
                        {(count > showingCount) &&
							<span>{`(Showing ${showingCount} out of ${count})`}</span>
                        }
                    </div>

                    <div className="row">
                        {data && data.length > 0 &&
							data.map((item, index) => {

							    if (dataType === "startup-relation" && item?.startup_data) {
							        item.entity_data = item.startup_data;
							        item.entity_data.type = "startup";
							    }

							    // skip empty/invalid data values
							    if (isEmpty(item.entity_data)) {
							        return;
							    }

							    let path = "";
							    if (!isEmpty(item.entity_data)) {
							        const entity = item.entity_data;
							        const entityType = prepEntityTypeLower(entity.type);
							        path = getItemWebUrl(entityType, entity.id, entity.code);

							        path = path && `https://magnitt.com/${path}`;
							    }

							    // get primary industry
							    if (dataType === "startup-relation" && item.industry_data && item.industry_data.length > 0) {
							        item.primary_industry = item.industry_data.filter((industry) => industry.is_primary)
							    }

							    return (
							        <a key={dataType + "_" + index}
							            className="col item-in-slider entity-container"
							            href={path}
							            target={"_blank"}
							            rel={path && "noopener noreferrer"}
							        >
							            <div className="row">
							                <div className="col-3 col-sm-4 mt-0">
							                    <div className="simple-detail-img">
							                        {(item.entity_data && item.entity_data.logo) ?
							                            <img src={item.entity_data.logo} />
							                            :
							                            <div>{item.entity_data && item.entity_data.name.charAt(0)}</div>
							                        }
							                    </div>
							                </div>
							                <div className="col-8 simple-detail-text">

							                    <p>{item.entity_data.name}</p>

							                    {dataType === "investor" &&
													<>
													    <p>
													        {item.invested_amount > 0 ? `${amountFormatter(item.invested_amount)} - ` : ``}
													        {!isEmpty(item.funding_stage_data) ? `${item.funding_stage_data.name}` : 'Undisclosed Round'}
													    </p>
													    <p>{moment(item.date_raised).format("MMM YYYY")}</p>
													</>
							                    }

							                    {dataType === "startup-relation" &&
													<p>{item.primary_industry && item.primary_industry.length > 0 ?
													    item.primary_industry[0].name : ""
													}</p>
							                    }

							                </div>
							            </div>
							        </a>
							    )
							})}
                    </div>

                </div>
            </>
        )
    }

}
