// helpers
import { amountFormatter ,formatEmployeeCount, formatDateFounded, formatBooleanInteger } from '../../../../../lib/helpers';

//api
import entityApi from '../../../../../apis/entityApi';

// local functions

// slider overlay, modified from original bootstrap version to add hide fade as well
const overlayAnimation = (type, user) => {

    // class must be added after delay for the fade animation to work
    const animationDelay = 500;

    if (type === "show") {

        $("#slider-overlay").css("display", 'unset');
        setTimeout(function () {
            $("#slider-overlay").css("opacity", 0.5)
        }, animationDelay);

    } else if (type === "hide") {

        $("#slider-overlay").css("opacity", 0);
        setTimeout(function () {
            $("#slider-overlay").css("display", 'none');
        }, animationDelay);

    }
};

const handleNullValues = (val) => {
    try {
        if (val) {
            return (val);
        } else {
            throw ('error');
        }
    } catch {
        return ("N/A");
    }
};

// exported functions

export const expandSlider = () => {
    const sliderComponent = document.getElementsByClassName("slider-container")[0];
    sliderComponent.setAttribute('data-expanded', 'true');

    const popoverElement = document?.getElementsByClassName('popover');
    // remove hover component when slider is expanded
    if (popoverElement[0]?.classList) {
        popoverElement[0].classList.remove('show');
    }

    overlayAnimation("show");
}

export const closeSlider = (user) => {
    const sliderComponent = document.getElementsByClassName("slider-container")[0];
    sliderComponent.setAttribute('data-expanded', 'false');
    overlayAnimation("hide");
    $('#companyEmailPopover').popover('hide');
}

// company overview data (for header section)
const getCompanyOverview = (entityType, data) => {

    let overview = [];
    if (entityType === 'startup') {
        overview.push(
            {
                field: 'Industry',
                value: handleNullValues(data.primary_industry_data && data.primary_industry_data.name)
            },
            {
                field: 'Total Funding',
                value: (data.fund_raised_data && data.fund_raised_data.amount) ?
                    amountFormatter(data.fund_raised_data.amount) : "N/A"
            },
        )
    } else if (entityType === 'investor') {
        overview.push(
            {
                field: 'Industry Focus',
                value: (data.industry_data && data.industry_data.length > 0 ?
                    handleNullValues(data.industry_data[0].name) : 'N/A')
            },
            {
                field: 'Estimated Deployed Capital',
                value: amountFormatter(data.estimated_deployed_capital)
            }
        )
    }

    // common to both
    overview.push(
        {
            field: 'Headquarters',
            value: handleNullValues(data.hq_data && data.hq_data.name)
        }
    )

    return (overview);
}

const getCompanyData = (entityType, data) => {
    let info = [];
    if (entityType === 'startup') {
        info.push(
            {
                field: 'Business Type',
                value: (data.business_type_data && data.business_type_data.length > 0) ?
                    data.business_type_data[0].name : "N/A",
                icon: "fas fa-briefcase"
            },
            {
                field: 'No. of Founders',
                value: handleNullValues(data.number_of_founders),
                icon: "fas fa-user-tie"
            },
            {
                field: 'Development Stage',
                value: (data.development_stage_data && data.development_stage_data.name) ?
                    data.development_stage_data.name : "N/A",
                icon: "fas fa-chart-line"
            },
            {
                field: 'Date Founded',
                value: formatDateFounded(data),
                icon: "far fa-calendar"
            },
            {
                field: 'Company Size',
                value: formatEmployeeCount(data),
                icon: "fas fa-users"
            },
            {
                field: 'Active',
                value: formatBooleanInteger(data.is_active),
                icon: "fas fa-power-off"
            }
        )
    } else if (entityType === 'investor') {
        info.push(
            {
                field: 'Investor Type',
                value: data && data.type_data ? handleNullValues(data.type_data.name) : 'N/A',
                icon: "fas fa-briefcase"
            },
            {
                field: 'No. of Investments',
                value: handleNullValues(data.number_of_investments),
                icon: "fas fa-money-check-alt"
            },
            {
                field: 'Development Stage',
                value: (data.development_stage_data && data.development_stage_data[0] ?
                    handleNullValues(data.development_stage_data[0].name) : 'N/A'),
                icon: "fas fa-chart-line"
            },
            {
                field: 'Geographical Focus',
                value: (data.geo_focus_data && data.geo_focus_data[0] ?
                    handleNullValues(data.geo_focus_data[0].name) : 'N/A'),
                icon: 'fas fa-globe-americas'
            }
        )
    }
    return (info);
}

export const getCompanyInfo = (entityType, data) => {
    return ({
        overview: getCompanyOverview(entityType, data),
        data: getCompanyData(entityType, data)
    })
}

export const getDataForSlider = (id, entityType) => {

    // common fields
    let fields = ['id', 'development_stage_data', "website", "twitter", "facebook", "linkedin", 'email_relations'];

    // fields for startup directory / ecosystem directory
    if (entityType === 'startup') {
        fields.push(
            'business_type_data',
            'number_of_founders',
            'date_founded', 'min_num_emp',
            'max_num_emp',
            'revenue_generating',
            'is_active',
            'fund_raised_data',
            'hq_data',
            'primary_industry_data',
        );
    } else if (['investor', 'corporate', 'enabler'].includes(entityType)) {
        fields.push(
            'estimated_deployed_capital',
            'geo_focus_data',
            'name',
            'ecosystem_type',
            'code',
            'owned_by',
            'logo_data',
            'description',
            'type_data',
            'hq_data',
            'number_of_investments',
            'number_of_funds',
            'number_of_startup_investments',
            'industry_data',
        );
    }

    return entityApi.getEntityDetails(id, entityType, fields)
};

