import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
// api
import startupMarketMapApi from '../../../../apis/startupMarketMapApi';
// helpers
import { prepareSelectParam, prepareFilterParam } from '../../../../lib/helpers';
// reusable
import TableFooter from './Table/TableFooter/TableFooter';
import Table from './Table/Table';
import MagnittLoader from '../../../../micro-frontends/MagnittLoader';
import { InvestorDetailModal, InvestorDetailRow } from './Table/InvestorDetailRow/InvestorDetailRow';
// constants
import { NO_DATA, ALLOWED_QUERY_OPERATOR, LOCATION_IDS } from '../../../../lib/constants';

import './StartupMarketMapTable.css';



class StartupMarketMapTable extends Component {

    constructor(props) {
        super(props);

        this.entityType = 'STARTUP';

        this.state = {
            data: [],
            page: 1,
            limit: 10,
            isLoading: true,
            isLoadingNewPage: false,
            field: ['id', 'name', 'code', 'logo_data', 'description', 'primary_industry_data', 'sub_industry_data', 'investors_data', 'date_founded', 'number_of_rounds_raised'],
            field: ['id', 'name', 'code', 'logo_data', 'description', 'primary_industry_data', 'sub_industry_data', 'investors_data', 'date_founded', 'number_of_rounds_raised'],
            filter: [
                { key: 'status', value: [1, 2], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal },
                // { key: "funding_verification_status_filter", value: [1], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'hq_id', value: [LOCATION_IDS.bahrain], op: ALLOWED_QUERY_OPERATOR.equal }
            ],
            selectableFields: [
                {
                    fieldName: "Description",
                    keyName: "description"
                },
                {
                    fieldName: "Primary Industry",
                    keyName: "primary_industry_data"
                },
                {
                    fieldName: "Sub-Industry",
                    keyName: "sub_industry_data"
                },
                {
                    fieldName: "Investors",
                    keyName: "investors_data"
                },
                {
                    fieldName: "Date Founded",
                    keyName: "date_founded"
                },
                {
                    fieldName: "Number of Rounds",
                    keyName: "number_of_rounds_raised"
                }

            ],
            tempFilter: [],
            tabName: "startupsTableTab"
        }
    }

    componentDidMount() {
        this.getStartups();
    }

    getStartups = () => {

        this.setState({ isLoadingNewPage: true });

        let { page, tabName } = this.state;
        const { limit, field, filter, tempFilter } = this.state;
        const { mapFilters, order } = this.props;

        // for pagination
        const { isPageChanged } = tempFilter;
        delete tempFilter.isPageChanged;

        let fields = prepareSelectParam(field);

        let defaultFilters = prepareFilterParam(filter);
        let customFilters = prepareFilterParam(mapFilters);
        let finalFilters = customFilters ? defaultFilters + `^,${customFilters}` : defaultFilters;

        let sort;
        if (order && order.field == 'total_fund' && order.order == 'desc') {
            sort = 'total_fund^:desc';
        } else if (order && order.field == 'total_fund' && order.order == 'asc') {
            sort = 'total_fund^:asc';
        } else {
            sort = 'last_date_raised^:desc';
        }

        // if page hasn't changed, set it to 1
        // this will reset to page 1 when page was > 1 and filters/sort/limit was changed
        if (!isPageChanged) {
            page = 1;
        }

        startupMarketMapApi.getStartups(fields, finalFilters, page, sort, limit).then((response) => {
            if (!isEmpty(response)) {
                const resp = response.data;
                this.setState({
                    data: resp.data,
                    isLoading: false,
                    isLoadingNewPage: false,
                    count: resp.navigation.total,
                    page
                }, () => this.props.updateSliderData(this.state.data, tabName));
            }
        });
    }

    render() {

        const { user, view, tabIsVisible } = this.props;
        const { isLoading, isLoadingNewPage, data, tabName } = this.state;
        const hasTableData = (data && data.length > 0);

        let content = null;
        let tableContent = {};

        tableContent.rows = [];
        tableContent.pinnedRow = [];

        if (data !== false && data.length) {
            data.map((startup, i) => {

                const elementId = `${startup.name}_${startup.id}_${tabName}`;
                // prepare fields
                // first column
                // logo, name
                let image;
                if (!isEmpty(startup.logo_data) && startup.logo_data.path) {
                    image = encodeURI(startup.logo_data.path);
                    image = <div
                        data-action="expand-slider"
                        data-entityid={startup.id}
                        id={elementId}
                        className='startupLogo'
                        style={{ backgroundImage: `url('${image}')` }}
                    ></div>
                } else {
                    image = <div
                        data-action="expand-slider"
                        data-entityid={startup.id}
                        id={elementId}
                        className='startupLogo'
                    >
                        <h2 className='startup-name'>{startup.name.substr(0, 1).toUpperCase()}</h2>
                    </div>
                }

                let nameHeader = 'Name';
                const nameRow = (
                    <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                            <a>
                                {image}
                            </a>
                            <div className="flex-column" style={{ marginLeft: 10, marginTop: -4 }}>
                                <a className="startup-name"
                                    data-action="expand-slider"
                                    data-entityid={startup.id}
                                    id={elementId}
                                >
                                    {startup.name}
                                </a>
                            </div>
                        </div>
                    </div>
                );

                // default field
                tableContent.header = [nameHeader]
                let fieldData = [{ name: nameRow }];

                // check selectable fields
                this.state.selectableFields.map((field) => {
                    const itemField = startup[field.keyName];
                    let tempData = "-";
                    tableContent.header.push(field.fieldName);

                    if (itemField || (itemField === 0 && field.keyName == "is_active")) {
                        switch (field.keyName) {
                        case "date_founded":
                            tempData = moment(itemField).format("MMM YYYY");
                            break;
                        case "sub_industry_data":
                            if (itemField && itemField.length > 0) {
                                let arrTemp = [];
                                itemField.forEach(ind => {
                                    if (ind && ind.name) {
                                        arrTemp.push(ind.name);
                                    }
                                })
                                tempData = arrTemp.length > 0 ? arrTemp.join(", ") : "-";
                            }
                            break;
                        case "primary_industry_data":
                        case "hq_data":
                            tempData = itemField.name ? itemField.name : "-";
                            break;
                        case "description":
                        case "number_of_rounds_raised":
                            tempData = itemField;
                            break;
                        case "investors_data":
                            if (!isEmpty(itemField)) {
                                tempData =
										<InvestorDetailRow
										    investedInName={startup.name}
										    investorData={itemField}
										    setState={(s, c) => { this.setState(s, c) }}
										    investedInId={startup.id}
										    investedInType={this.entityType.toLowerCase()}
										    userHasDirectoryAccess={this.state.isSubscribedUser}
										    selectedFundingStageName={false}
										/>;
                            }
                            break;
                        default:
                            tempData = "-";
                        }
                    }

                    fieldData.push({ [field.keyName]: tempData });
                })

                // required for funding sub row functionality
                fieldData.push({ id: startup.id, code: startup.code, element_name: startup.name });

                tableContent.rows.push(fieldData);

            })

            content = (
                <Table content={tableContent}
                    user={user}
                    entityType={this.entityType}
                    showFundingSubRow={false}
                    state={this.state}
                    filterValues={this.filterValues}
                    tempFilter={this.state.tempFilter}
                    setState={(s, c) => { this.setState(s, c) }}
                />
            )

        }

        return (
            <div className="network-map-main">

                {(isLoading && tabIsVisible) &&
					<MagnittLoader data={{ height: "300px", background: "transparent" }} />
                }

                {!isLoading &&
					<>
					    {content}
					    {/* only display pagination for final view, not preview */}
					    {view === 'finalMap' &&
							<TableFooter
							    // data
							    count={this.state.count}
							    data={this.state.data}
							    loadingDirPage={isLoadingNewPage}
							    user={this.props.user}
							    page={this.state.page}
							    limit={this.state.limit}
							    tempFilter={this.state.tempFilter}
							    isCustomMarketMap={true}
							    // func
							    clearFilter={() => { }}
							    filterValues={this.getStartups}
							    setState={(s, c) => { this.setState(s, c) }}
							/>
					    }

					    {!hasTableData &&
							<div className="no-chart-data">
							    <h6>{NO_DATA}</h6>
							</div>
					    }

					    <InvestorDetailModal
					        investedInName={this.state.investedInName}
					        investedInId={this.state.investedInId}
					        investorData={this.state.selectedInvestorModal}
					        userHasDirectoryAccess={true}
					        selectedFundingStageName={false}
					    />
					</>
                }

            </div>
        )
    }
}

export default StartupMarketMapTable;
